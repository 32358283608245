import React, { Suspense } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { isSSR, actualCountry } from "../utils/constants"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import Spinner from "../components/Spinner"
import Resource from "../config/language.json"
import CardResource from "../components/ProductDetail/language.json"

const ProductDetail = React.lazy(() => import("../components/ProductDetail"))
const component = Resource[process.env.GATSBY_SITE_VERSION]
const adicionalComponent = CardResource[process.env.GATSBY_SITE_VERSION]

export default () => {
  const data = useStaticQuery(graphql`
    query {
      improveMortgage: file(
        relativePath: {
          eq: "mx/landings/mejora-hipoteca-creditoreduce-la-hipoteca-de-tu-casa-iban-online-mexico.jpg"
        }
      ) {
        ...customFragmentImageFluid
      }
      improveMortgageDo: file(
        relativePath: {
          eq: "do/landings/prestamo-para-reduccion-tasa-hipotecaria-iban-online-do-mejora-tu-cuota.jpg"
        }
      ) {
        ...customFragmentImageFluid
      }
    }
  `)

  return (
    <>
      {!isSSR && (
        <Suspense fallback={<Spinner />}>
          <SEO
            title={component.improveYourMortgageCredit.SEO.title}
            keywords={component.improveYourMortgageCredit.SEO.keywords}
            description={component.improveYourMortgageCredit.SEO.description}
          />
          <Layout>
            <ProductDetail
              pageData={{
                alt: component.improveYourMortgageCredit.pageData.alt,
                credit: component.improveYourMortgageCredit.pageData.credit,
                minAmount: component.improveYourMortgageCredit.minAmount,
                minLoanDurationInMonths:
                  component.improveYourMortgageCredit.minLoanDurationInMonths,
                titleProduct:
                  component.improveYourMortgageCredit.pageData.titleProduct,
                textProduct:
                  component.improveYourMortgageCredit.pageData.textProduct,
                href: component.improveYourMortgageCredit.pageData.href,
                textBenefits:
                  component.improveYourMortgageCredit.pageData.textBenefits,
                image:
                  actualCountry === "do"
                    ? data.improveMortgageDo.childImageSharp.fluid
                    : data.improveMortgage.childImageSharp.fluid,
                cardDataUp: adicionalComponent.cardMortgageCredit,
                leftSymmetrical:
                  component.improveYourMortgageCredit.pageData.leftSymmetrical,
                applicationType:
                  component.improveYourMortgageCredit.pageData.applicationType,
                form: component.improveYourMortgageCredit.form.inputs,
                list: component.improveYourMortgageCredit.pageData.list,
                secondTextBenefits:
                  component.improveYourMortgageCredit.pageData
                    .secondTextBenefits,
                cardDataHeader:
                  component.improveYourMortgageCredit.pageData.cardDataHeader,
              }}
            />
          </Layout>
        </Suspense>
      )}
    </>
  )
}
